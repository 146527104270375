import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styled from 'styled-components'
import { IReactSliceComponent } from '../../core/application/types'
import { breakpoints } from '../../theme/breakpoints'

const SectionTitle: React.VFC<IReactSliceComponent> = ({ slice }) => (
  <TitleWrapper className='sectionTitle'>
    <PrismicRichText field={slice.primary.title} />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.font.gotham};
  margin-bottom: 2rem;
  text-align: center;

  @media ${breakpoints.tablet} {
    text-align: start;
  }
`

export default SectionTitle
