import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styled from 'styled-components'
import { IReactSliceComponent } from '../../core/application/types'
import { breakpoints } from '../../theme/breakpoints'

const TutorialStep: React.VFC<IReactSliceComponent> = ({ slice }) => {
  return (
    <Section className='TutorialStep' reverse={slice.variation === 'reverseTutorialStep'}>
      <SectionContent>
        <SectionTitle>
          <PrismicRichText field={slice.primary.title} />
        </SectionTitle>
        <SectionText>
          <PrismicRichText field={slice.primary.description} />
        </SectionText>
      </SectionContent>
      <SectionImage>
        <img src={slice.primary.image.url} alt={slice.primary.image.alt} decoding='async' />
      </SectionImage>
    </Section>
  )
}

interface ISectionProps {
  reverse?: boolean
}

const Section = styled.div<ISectionProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.tablet} {
    flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
    justify-content: space-between;
  }
  

  &:not(:last-child) {
    margin-bottom: 5rem;
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    max-width: 40rem;
  }

  @media ${breakpoints.laptopM} {
    max-width: 50rem;
  }
`

const SectionTitle = styled.div`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-family: ${({ theme }) => theme.font.gotham};
  font-size: 3.4rem;
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 2rem;
`

const SectionText = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.yetAnotherGrey};

  > p:not(:last-child) {
    margin-bottom: 1rem;
  }
`


const SectionImage = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
    height: 20rem;
  }

  @media ${breakpoints.laptopM} {
    height: 50rem;
  }

  img {
    width: auto;
    height: 100%;
  }
`

export default TutorialStep
