import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useRouter } from 'next/router'

import styled, { css } from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'
import routes from '../../../core/application/routes'
import { GamePlatform } from '../../../core/domain/models'
import { USER_AVATAR_PLACEHOLDER } from '../../../core/constants'
import { prettyGamePlatform } from '../../../core/application/utils/gamePlatformUtils'
import { createCloudinaryThumb } from '../../../core/application/utils/imageUtils'

enum LeaderboardTab {
  GAMES,
  USERS
}

export interface IUserInfo {
  id: string
  firstName: string
  lastName: string
  username: string
  city: string | null
  country: string | null
  avatar: string | null
  games: number
  exchanges: number
}

export interface IGameInfo {
  id: string
  name: string
  platform: GamePlatform
  cover: string | null
  owners: number
  seekers: number
}

export interface ILeaderboardProps {
  users: Array<IUserInfo>
  games: Array<IGameInfo>
}

const Leaderboard: React.VFC<ILeaderboardProps> = ({ users, games }) => {
  const router = useRouter()
  const [currenTab, setCurrenTab] = useState<LeaderboardTab>(LeaderboardTab.GAMES)

  const openUserDetailPage = async (userId: string) => {
    await router.push({
      pathname: routes.USER_DETAILS,
      query: {
        userId
      }
    })
  }

  const openGameDetailPage = async (gameId: string) => {
    await router.push({
      pathname: routes.GAME_DETAILS,
      query: {
        gameId
      }
    })
  }


  return (
    <Container>
      <Header>
        <Title>
          Leaderboard
        </Title>
        <TabContainer>
          {(games.length > 0) &&
            <Tab current={currenTab === LeaderboardTab.GAMES} onClick={() => setCurrenTab(LeaderboardTab.GAMES)}>
              <FormattedMessage id='Index.Leaderboard.Tab1' />
            </Tab>}
          {(users.length > 0) &&
            <Tab current={currenTab === LeaderboardTab.USERS} onClick={() => setCurrenTab(LeaderboardTab.USERS)}>
              <FormattedMessage id='Index.Leaderboard.Tab2' />
            </Tab>}
        </TabContainer>
      </Header>
      {currenTab === LeaderboardTab.USERS &&
        <>
          {users.map((it, index) => (
            <Table key={index}>
              <FirstCell className={`first-cell row-${index}`}>
                <UserAvatar
                  onClick={() => openUserDetailPage(it.id)}
                  filePath={it.avatar ? createCloudinaryThumb(it.avatar, 200) : USER_AVATAR_PLACEHOLDER} />
                <UserInfo>
                  <div className='label'>
                    {it.city} - {it.country}
                  </div>
                  <div className='value'>
                    {it.username}
                  </div>
                </UserInfo>
              </FirstCell>
              <TableCell className={`second-cell row-${index}`}>
                <span className='label'>
                  <FormattedMessage id='Index.Leaderboard.OwnedGames' />
                </span>
                <span className='value'>{it.games}</span>
              </TableCell>
              <TableCell className={`third-cell row-${index}`}>
                <span className='label'>
                  <FormattedMessage id='Index.Leaderboard.ExchangedGames' />
                </span>
                <span className='value'>{it.exchanges}</span>
              </TableCell>
            </Table>
          ))}
        </>
      }
      {currenTab === LeaderboardTab.GAMES &&
        <>
          {games.map((it, index) => (
            <Table key={index}>
              <FirstCell className={`first-cell row-${index}`}>
                {it.cover && (
                  <GameCoverWrapper>
                    <GameCover
                      onClick={() => openGameDetailPage(it.id)} image={it.cover} />
                  </GameCoverWrapper>
                )}
                <GameInfo>
                  <div className='label'>
                    {prettyGamePlatform(it.platform)}
                  </div>
                  <div className='value'>
                    {it.name}
                  </div>
                </GameInfo>
              </FirstCell>
              <TableCell className={`second-cell row-${index}`}>
                <span className='label'>
                  <FormattedMessage id='Index.Leaderboard.Owners' />
                </span>
                <span className='value'>{it.owners}</span>
              </TableCell>
              <TableCell className={`third-cell row-${index}`}>
                <span className='label'>
                  <FormattedMessage id='Index.Leaderboard.Seekers' />
                </span>
                <span className='value'>{it.seekers}</span>
              </TableCell>
            </Table>
          ))}
        </>
      }

    </Container>
  )
}

const Container = styled.div`
  display: block;
  padding: 2rem ${({ theme }) => theme.padding.mobilePageHorizontalPadding};


  @media ${breakpoints.tablet} {
    display: block;
    padding: 2rem ${({ theme }) => theme.padding.mobilePageHorizontalPadding};
  }

  @media ${breakpoints.laptop} {
    padding: 7.5rem ${({ theme }) => theme.padding.pageHorizontalPadding};
  }

`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  align-items: center;
  margin-bottom: 6rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.font.gotham};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.size.subtitle};

  }
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.color.lighterGrey};
`

interface ITabProps {
  current?: boolean
}

const Tab = styled.div<ITabProps>`
  color: ${({ theme, current }) => current ? theme.color.white : theme.color.darkGrey};
  background-color: ${({ theme, current }) => current ? theme.color.primary : theme.color.lightGrey};
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.small};
  padding: 1.4rem 3.2rem;
  cursor: pointer;
  transition: background-color 200ms linear;


  ${({ current }) => current && css`
    border-radius: 0.4rem;
    transform: scale(1.05);
    box-shadow: 0 10px 20px 0 rgba(136, 99, 232, 0.5);
    z-index: 2;
  `};
`

const Table = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;

  @media ${breakpoints.laptopM} {
    grid-template-columns: 2fr 1fr 3fr;
  }
`


const TableCell = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media ${breakpoints.tablet} {
    padding: 2rem;
    row-gap: 1.5rem;
  }

  border-bottom: solid 1px ${({ theme }) => theme.color.lightGrey};

  &.row-0 {
    border-top: solid 1px ${({ theme }) => theme.color.lightGrey};

  }

  &.row-0.first-cell {
    border-top-left-radius: 0.4rem;
  }

  &.row-0.third-cell {
    border-top-right-radius: 0.4rem;
  }

  &.row-2.first-cell {
    border-bottom-left-radius: 0.4rem;
  }

  &.row-2.third-cell {
    border-bottom-right-radius: 0.4rem;
  }


  &.first-cell {
    border-left: solid 1px ${({ theme }) => theme.color.lightGrey};
  }

  &.second-cell {
    border-left: solid 1px ${({ theme }) => theme.color.lightGrey};
    border-right: solid 1px ${({ theme }) => theme.color.lightGrey};
  }

  &.third-cell {
    border-right: solid 1px ${({ theme }) => theme.color.lightGrey};
  }

  .label {
    font-weight: ${({ theme }) => theme.weight.semi};
    font-size: ${({ theme }) => theme.size.small};
    color: ${({ theme }) => theme.color.grey};
  }

  .value {
    font-weight: ${({ theme }) => theme.weight.semi};
    font-size: ${({ theme }) => theme.size.normal};
    color: ${({ theme }) => theme.color.black};
  }
`

const FirstCell = styled(TableCell)`
  flex-direction: row;
  column-gap: 1rem;

  @media ${breakpoints.tablet} {
    column-gap: 3rem;
  }
`

interface IAvatar {
  filePath: string
}


const UserAvatar = styled.div<IAvatar>`
  border-radius: 0.4rem;
  background-image: linear-gradient(rgba(136, 99, 232, 0.55), rgba(136, 99, 232, 0.55)), ${({ filePath }) => `url(${filePath})`};
  background-size: cover;
  background-position: center center;
  height: 3.5rem;
  width: 3.5rem;

  cursor: pointer;

  @media ${breakpoints.tablet} {
    height: 5rem;
    width: 5rem;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .value {
    font-weight: ${({ theme }) => theme.weight.bold};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.black};

    @media ${breakpoints.tablet} {
      font-size: 2rem;
    }
  }
`

interface IGameCover {
  image: string
}

const GameCoverWrapper = styled.div`

`

const GameCover = styled.div<IGameCover>`
  height: 3.5rem;
  width: 3.5rem;

  @media ${breakpoints.laptop} {
    height: 5rem;
    width: 5rem;
  }

  cursor: pointer;

  background-image: linear-gradient(rgba(136, 99, 232, 0.55), rgba(136, 99, 232, 0.55)), ${({ image }) => `url(${image})`};
  background-size: cover;
  border-radius: 50%;

`

const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .value {
    font-weight: ${({ theme }) => theme.weight.bold};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.black};

    @media ${breakpoints.tablet} {
      font-size: 2rem;
    }
  }
`

export default Leaderboard
