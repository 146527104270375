// This file is generated by Slice Machine. Update with care!

import SectionTitle from './SectionTitle';
import TutorialStep from './TutorialStep';

export {
	SectionTitle,
	TutorialStep,
};

export const components = {
	section_title: SectionTitle,
	tutorial_step: TutorialStep,
};
