import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from '../../../theme/breakpoints'

const Tutorial = () => {
  return (
    <Container>
      <Title>
        <FormattedMessage id='Index.Tutorial.Title' />
      </Title>
      <Section>
        <SectionContent>
          <SectionTitle>
            I. Ajoutez vos jeux, renseignez vos souhaits.
          </SectionTitle>
          <SectionText>
            <p>
              Un jeu qui prend la poussière sur l’étagère, c’est le moment de l’échanger.
            </p>
            <p>
              Plus le nombre de jeux présent dans votre bibliothèque est important, plus vous avez de chance d’obtenir
              des
              correspondances dans votre liste de souhaits.
            </p>
          </SectionText>
        </SectionContent>
        <SectionImage>
          <img src='/images/tutorial-asset-1@3x.png' />
        </SectionImage>
      </Section>
      <Section reverse>
        <SectionContent>
          <SectionTitle>
            II. Relaxez-vous, nous cherchons à votre place.
          </SectionTitle>
          <SectionText>
            <p>
              Swappy se charge de faire des correspondances en temps réel entre les bibliothèques des uns et les listes
              de souhaits des autres.
              Pratique. Vous serez immédiatement notifiés lorsqu’une correspondance sera trouvée.
            </p>
          </SectionText>
        </SectionContent>
        <SectionImage>
          <img src='/images/tutorial-asset-2@3x.png' />
        </SectionImage>
      </Section>
      <Section>
        <SectionContent>
          <SectionTitle>
            III. Choissisez parmi les correspondances trouvées.
          </SectionTitle>
          <SectionText>
            <p>
              Simple. Décidez en fonction de vos préférences (qualité, distance géographique, jeu demandé en échange,
              notation..).
              Vous avez trouvé votre bonheur ? c’est à vous de jouer, faites une demande d’échange.
            </p>
          </SectionText>
        </SectionContent>
        <SectionImage>
          <img src='/images/tutorial-asset-3@3x.png' />
        </SectionImage>
      </Section>
      <Section reverse>
        <SectionContent>
          <SectionTitle>
            IV. Discutez pour finaliser votre échange.
          </SectionTitle>
          <SectionText>
            <p>
              Basique. Concluez votre swapp en tchatant librement avec le swapper. Définissez ensemble le moyen
              d’échange idoine, physique ou par envoi postal. Il ne vous restera plus qu’à valider la bonne réception du
              jeu.
            </p>
          </SectionText>
        </SectionContent>
        <SectionImage>
          <img src='/images/tutorial-asset-4@3x.png' />
        </SectionImage>
      </Section>
      <Section>
        <SectionContent>
          <SectionTitle>
            V. Jouez, échangez, et jouez encore.
          </SectionTitle>
          <SectionText>
            <p>
              Vous venez de platinez le jeu ? rajoutez le à votre bibliothèque et continuez d’échanger à l’infini.
            </p>
          </SectionText>
        </SectionContent>
        <SectionImage>
          <img src='/images/tutorial-asset-5-dirty.png' />
        </SectionImage>
      </Section>
    </Container>
  )
}

export default Tutorial


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled.h2`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.font.gotham};
  margin-bottom: 2rem;
  text-align: center;

  @media ${breakpoints.tablet} {
    text-align: start;
  }
`

interface ISectionProps {
  reverse?: boolean
}

const Section = styled.div<ISectionProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.tablet} {
    flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
    justify-content: space-between;
  }

  &:not(:last-child) {
    margin-bottom: 5rem;
  }
`

const SectionImage = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: block;
    height: 20rem;
  }

  @media ${breakpoints.laptopM} {
    height: 40rem;
  }
  
  img {
    width: auto;
    height: 100%;
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    max-width: 30rem;
  }
`

const SectionTitle = styled.h3`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-family: ${({ theme }) => theme.font.gotham};
  font-size: 3.4rem;
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 2rem;
`

const SectionText = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.yetAnotherGrey};

  > p:not(:last-child) {
    margin-bottom: 1rem;
  }
`
