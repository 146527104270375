import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import routes from '../../../core/application/routes'
import { LargeButton } from '../../common/buttons'
import { breakpoints } from '../../../theme/breakpoints'

const Hero: React.VFC = () => {
  return (
    <Container>
      <TitleWrapper>
        <BigTitle>
          <FormattedMessage id='Index.BigTitle.Part1' /><br />
          <FormattedMessage id='Index.BigTitle.Part2' />
        </BigTitle>
        <SubTitle>
          <FormattedMessage id='Index.SubTitle.Part1' /><br />
          <FormattedMessage id='Index.SubTitle.Part2' /><br />
          <span><FormattedMessage id='Index.SubTitle.Part3' /></span>
        </SubTitle>
        <Link href={routes.REGISTRATION}>
          <a>
            <LargeButton>
              <FormattedMessage id='Index.StartButton.Label' />
            </LargeButton>
          </a>
        </Link>
      </TitleWrapper>
      <ImageWrapper>
        <img src='/images/index_hero_illustration.svg' />
      </ImageWrapper>
    </Container>
  )
}


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5rem 2.5rem 0 2.5rem;
  justify-content: center;


  @media ${breakpoints.laptop} {
    margin-top: 5rem;
    margin-bottom: 10rem;
    padding: 0 12rem;
  }

  @media ${breakpoints.laptopM} {
    margin-top: 10rem;
    margin-bottom: 20rem;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;


  @media ${breakpoints.laptop} {
    flex-basis: 60%;
    justify-content: unset;
    align-items: unset;
  }

  @media ${breakpoints.laptopM} {
    flex-basis: 75%;
  }
`

const BigTitle = styled.h1`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: 4.5rem;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  margin-bottom: 2rem;

  @media ${breakpoints.laptop} {
    text-align: left;
    font-size: 5rem;
  }
`

const SubTitle = styled.p`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  margin-bottom: 2rem;
  line-height: 2rem;

  span {
    font-weight: ${({ theme }) => theme.weight.bold};
  }

  @media ${breakpoints.laptop} {
    text-align: left;
  }
`

const ImageWrapper = styled.div`
  display: none;
  flex: 1;
  z-index: 1;
  
  @media ${breakpoints.laptop} {
    display: block;
    position: relative;

    img {
      position: absolute;
      right: -5rem;
      top: -2rem;
      width: 44rem;
      max-width: unset !important;
    }
    
  }
  
  @media ${breakpoints.laptopM} {
    
    img {
      right: 5rem;
      top: -6rem;
      width: 75rem;
    }
  }
`

export default Hero
