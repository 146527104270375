import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'
import UserCard from './UserCard'

const UserRow = () => {
  return (
    <Container>
      <UserCard />
      <UserCard />
      <UserCard />
      <UserCard />
      <Break />
      <TeaserContainer>
        <Teaser>
          <FormattedMessage id='Index.UserRow.Teaser.Part1' /><br /><FormattedMessage id='Index.UserRow.Teaser.Part2' />
        </Teaser>
      </TeaserContainer>
    </Container>
  )
}

export default UserRow


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 3rem;
  row-gap: 2rem;
  border-radius: 0.4rem;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};

  & > :nth-child(4) {
    display: none;
    @media ${breakpoints.laptopM} {
      display: flex;
    }
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    column-gap: 3.5rem;
  }
`

const Break = styled.div`
  display: none;
  flex-basis: 100%;
  height: 0;

  @media ${breakpoints.tablet} {
    display: block;
  }

  @media ${breakpoints.laptop} {
    display: none;
  }
`

const TeaserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  :before {
    display: none;

    @media ${breakpoints.laptop} {
      display: block;
    }

    content: '';
    position: absolute;
    margin-left: -15.5rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
    width: 14rem;
    height: 5rem;
  }
`

const Teaser = styled.div`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-family: ${({ theme }) => theme.font.gotham};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};
  text-align: center;

  @media ${breakpoints.tablet} {
    text-align: end;
  }
`
