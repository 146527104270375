import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import routes from '../../../core/application/routes'

const UserCard = () => {
  const router = useRouter()

  const openUserDetailPage = async () => {
    await router.push({
      pathname: routes.USER_DETAILS,
      query: {
        userId: 1
      }
    })
  }

  return (
    <Container onClick={openUserDetailPage}>
      <AvatarBackground>
        <Avatar />
      </AvatarBackground>
      <Column>
        <UserName>
          Ryan Gosling
        </UserName>
        <UserPseudo>
          @rGosling
        </UserPseudo>
      </Column>
    </Container>
  )
}

export default UserCard

const Container = styled.div`
  height: 5rem;
  display: flex;
  flex-direction: row;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  border-radius: 0.4rem;
  cursor: pointer;
`
const AvatarBackground = styled.div`
  width: 5rem;
`

const Avatar = styled.div`
  height: 100%;
  border-radius: 0.4rem;
  background-image: linear-gradient(rgba(136, 99, 232, 0.7), rgba(136, 99, 232, 0.7)), url('/images/ryan.webp');
  background-size: cover;
  background-position: center center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 1rem 2rem;
`

const UserName = styled.span`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};
`

const UserPseudo = styled.span`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.grey};
`

