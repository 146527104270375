import React from 'react'
import type { NextPage } from 'next'
import { GetStaticProps, GetStaticPropsResult } from 'next'
import styled from 'styled-components'
import { SliceZone } from '@prismicio/react'
import * as Sentry from '@sentry/nextjs'
import { Leaderboard } from '../components/pages/index'
import { breakpoints } from '../theme/breakpoints'
import { createClient } from '../prismic'
import { components } from '../slices'
import { findCorrectPrismicLocale } from '../core/application/utils/localUtils'
import { createServerDependencies } from '../core/application/dependencies'
import { GamePlatform } from '../core/domain/models'
import { INDEX_PAGE_CACHE_TIMELIFE } from '../core/config'
import { safeParseInt } from '../core/application/utils/numberUtils'
import Hero from '../components/pages/index/Hero'


export interface ILeaderboardUserData {
  id: string
  firstName: string
  lastName: string
  username: string
  city: string | null
  country: string | null
  avatar: string | null
  games: number
  exchanges: number
}

export interface ILeaderboardGameData {
  id: string
  name: string
  platform: GamePlatform
  cover: string | null
  owners: number
  seekers: number
}


export interface IHomePageContext {
  slices: Array<any>
  leaderboard: {
    users: Array<ILeaderboardUserData>
    games: Array<ILeaderboardGameData>
  }
}

const Home: NextPage<IHomePageContext> = ({ slices, leaderboard }) => {

  return (
    <PageContainer>
      <Hero />
      {(slices && slices.length > 0) &&
        <SliceZoneWrapper>
          <SliceZone
            slices={slices}
            components={components}
          />
        </SliceZoneWrapper>
      }
      {leaderboard && <LeaderboardWrapper>
        <Leaderboard
          users={leaderboard.users}
          games={leaderboard.games} />
      </LeaderboardWrapper>}
    </PageContainer>
  )
}


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PageHeader = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media ${breakpoints.tablet} {
    margin-bottom: 5rem;
  }

  @media ${breakpoints.laptop} {
    margin-top: 14rem;
    margin-bottom: 10rem;
  }
`

const BigTitle = styled.h1`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: 4.5rem;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  margin-bottom: 2rem;


  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.size.bigger};
  }
`

const SubTitle = styled.h4`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  margin-bottom: 2rem;

  span {
    font-weight: ${({ theme }) => theme.weight.bold};
  }
`

const UserRowWrapper = styled.div`
  margin-top: 2rem;

  @media ${breakpoints.tablet} {
    margin-top: 10rem;
  }
`

const SliceZoneWrapper = styled.div`
  width: 100%;
  padding: 5rem ${({ theme }) => theme.padding.mobilePageHorizontalPadding};

  @media ${breakpoints.laptop} {
    padding: 0 ${({ theme }) => theme.padding.pageHorizontalPadding};
  }
`

const LeaderboardWrapper = styled.div`
  width: 100%;
  border: solid 1px #eaeaea;
`


export const getStaticProps: GetStaticProps<IHomePageContext> = async (context): Promise<GetStaticPropsResult<IHomePageContext>> => {
  const { swappyProvider } = createServerDependencies()
  const lang = findCorrectPrismicLocale(context.locale)
  const client = createClient({ previewData: context.previewData })

  let slices: Array<any> = []
  let leaderboardUsers: Array<ILeaderboardUserData> = []
  let leaderboardGames: Array<ILeaderboardGameData> = []

  try {
    const responseArray = await Promise.all([
      swappyProvider.getGlobalStats(),
      client.getSingle('homepage', {
        lang
      })
    ])
    slices = responseArray[1].data.slices
    const globalStats = responseArray[0]
    leaderboardGames = globalStats.topGames.map(it => ({
      id: it.game.id,
      name: it.game.name,
      platform: it.game.platform,
      cover: it.game.coverUrl ?? null,
      owners: it.owners,
      seekers: it.seekers
    }))
    leaderboardUsers = globalStats.topUsers.map(it => ({
      id: it.user.id,
      firstName: it.user.firstName,
      lastName: it.user.lastName,
      username: it.user.username,
      avatar: it.user.avatar ?? null,
      country: it.user.country ?? null,
      city: it.user.city ?? null,
      games: it.games,
      exchanges: it.exchanges
    }))

  } catch (err) {
    console.error(err)
    Sentry.captureException(err)
  }


  return {
    revalidate: safeParseInt(INDEX_PAGE_CACHE_TIMELIFE),
    props: {
      slices,
      leaderboard: {
        users: leaderboardUsers,
        games: leaderboardGames
      }
    }
  }
}

export default Home
